import { MDCDrawer } from "@material/drawer"
import { MDCTopAppBar } from "@material/top-app-bar"
import { MDCMenu } from '@material/menu'

$(document).ready ->
  ####
  # Top app bar initialization
  ####
  app_bar = document.getElementById('app-bar')
  topAppBar = MDCTopAppBar.attachTo(app_bar)

  ####
  # Top app bar with scoping object
  ####
  # if a scoping object is present, show the scroll shadow at the bottom of said object instead of the top app bar
  # I have no idea whether the order of scrolling events (first the regular top app bar event setting the scroll class and then this which removes it) is guaranteed in any way, in practice this did work though
  # OPTIMIZE: suppress top app bar scroll event altogether
  # NOTE: Probably wont work with scroll targets defined for the app bar other than window (which is the default)
  scoping_object = document.querySelector('.hs-scoping-object')
  if scoping_object
    window.addEventListener 'scroll', ->
      scroll_position = window.pageYOffset
      if scroll_position <= 0
        scoping_object.classList.remove 'hs-scoping-object-scrolled'
      else
        scoping_object.classList.add 'hs-scoping-object-scrolled'
        topAppBar.foundation.adapter.removeClass('mdc-top-app-bar--fixed-scrolled')

  # context menu
  menu_element = app_bar.querySelector('.mdc-menu')
  if menu_element
    menu = MDCMenu.attachTo(menu_element)
    app_bar.querySelector('#menu-button').addEventListener 'click', ->
      menu.open = !menu.open

  ####
  # Application drawer initialization
  ####
  drawer_element = document.querySelector('.mdc-drawer')
  if drawer_element
    drawer = MDCDrawer.attachTo(drawer_element)
    # `setScrollTarget` is called in the snippet from the MDC documentation but seems to have like no effect
    # topAppBar.setScrollTarget(document.getElementById('main-content'))
    topAppBar.listen 'MDCTopAppBar:nav', ->
      drawer.open = !drawer.open

  ####
  # Search functionality
  ####
  search_icon = app_bar.querySelector('.hs-top-app-bar--search-icon')
  search_form = app_bar.querySelector('.hs-top-app-bar--search-form')

  toggle_search_form = ->
    app_bar_title = app_bar.querySelector('.mdc-top-app-bar__title')
    if search_form.style.display == 'block'
      search_form.style.removeProperty('display')
      app_bar_title.style.removeProperty('display')
    else
      app_bar_title.style.display = 'none'
      search_form.style.display = 'block'
      search_form.querySelector('input').focus()

  if search_icon
    search_icon.addEventListener 'click', toggle_search_form

  if search_form
    if search_form.querySelector('input').value != ''
      toggle_search_form()

    # TODO-digibase-home: WTF?!? The icon button in the query input field does not submit the form without the code below.
    search_button = search_form.querySelector('button')
    search_button.addEventListener 'click', ->
      search_form.submit()
