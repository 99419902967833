import {MDCBanner} from '@material/banner'
import {MDCCheckbox} from '@material/checkbox'
import {MDCDialog} from '@material/dialog'
import {MDCFormField} from '@material/form-field'
import {MDCList} from '@material/list'
import {MDCLinearProgress} from '@material/linear-progress'
import {MDCRipple} from '@material/ripple'
import {MDCSelect} from '@material/select'
import {MDCSnackbar} from '@material/snackbar'
import {MDCSwitch} from '@material/switch/deprecated'
import {MDCTabBar} from '@material/tab-bar'
import {MDCTextField} from '@material/textfield'
import {MDCTooltip} from '@material/tooltip'
import moment from 'moment'
import MaterialDateTimePicker from 'material-datetime-picker'
import * as LayoutSize from './lib/layout_size'

$(document).ready ->
  ###
  # Switch between fixed open drawer for larger viewports and modal drawer for smaller ones.
  ###
  adjust_layout = ->
    drawer = document.querySelector('aside.mdc-drawer')
    return unless drawer

    main_content = document.getElementById('main-content')
    unsteady_bottom_nav = document.querySelector('footer.hs-bottom-nav:not(.hs-bottom-nav--steady)')
    toggle_visibility_elements = [
      document.querySelector('.mdc-drawer-scrim'),
      document.getElementById('app-drawer-button'),
      unsteady_bottom_nav
    ]

    hidden_class = 'hs-hidden'
    small_drawer_class = 'mdc-drawer--modal'
    large_drawer_classes = ['mdc-drawer--dismissible', 'mdc-drawer--open', 'hs-drawer--fixed']
    bottom_nav_adjust_class = 'hs-bottom-nav--fixed-adjust'

    if LayoutSize.isAtLeast('medium')
      drawer.classList.add large_drawer_class for large_drawer_class in large_drawer_classes
      drawer.classList.remove(small_drawer_class)
      for element in toggle_visibility_elements
        element.classList.add(hidden_class) if element
      main_content.classList.remove(bottom_nav_adjust_class) if unsteady_bottom_nav
    else
      drawer.classList.add(small_drawer_class)
      drawer.classList.remove large_drawer_class for large_drawer_class in large_drawer_classes
      for element in toggle_visibility_elements
        element.classList.remove(hidden_class) if element
      main_content.classList.add(bottom_nav_adjust_class) if unsteady_bottom_nav

  window.addEventListener('resize', adjust_layout)
  adjust_layout()

  # Progressbar initialization
  window.set_progressbar_trigger = (element, event_name) ->
    element.addEventListener event_name, (event) ->
      window.progressbar.foundation.setDeterminate(false)
      window.progressbar.open()

  progressbar_div = document.querySelector('.mdc-linear-progress')
  if progressbar_div
    window.progressbar = new MDCLinearProgress(progressbar_div)
    document.querySelectorAll('a:is(not([target="_blank"]), not([href="#"]))').forEach (element) ->
      window.set_progressbar_trigger element, 'click'
    # NOTE: If we ever introduce AJAX forms that do not redirect after completion this must be adjusted to ignore them.
    document.querySelectorAll('form').forEach (element) ->
      window.set_progressbar_trigger element, 'submit'

  # List initialization
  # https://material.io/components/lists/web#using-lists
  window.initializeListItemRipples = (list) ->
    list.listElements.forEach (list_element) ->
      # HACK: We also need to set the progressbar trigger for list items fetched by pagy auto incremental. That elements which already have a ripple set also have the progressbar trigger set is a rather implicit than explicit fact.
      unless list_element.classList.contains('mdc-ripple-upgraded')
        parent = list_element.parentElement
        window.set_progressbar_trigger(parent, 'click') if parent.tagName == 'A'
        MDCRipple.attachTo list_element

  document.querySelectorAll('.mdc-deprecated-list').forEach (element) ->
    list = new MDCList(element)
    window.pagy_list = list if list.id = 'pagy_container'
    window.initializeListItemRipples list

  window.appendToPagyAutoIncremental = (list_items) ->
    $('#pagy_container').append list_items

  # Checkbox initialization
  document.querySelectorAll('.mdc-checkbox').forEach (element) ->
    new MDCCheckbox(element)
    new MDCFormField(element.closest('.mdc-form-field'))
  document.querySelectorAll('.hs-checkbox--invalid').forEach (element) ->
    element.closest('.mdc-form-field').addEventListener 'click', (event) ->
      element.classList.remove('hs-checkbox--invalid')

  # Ripple effect initialization
  document.querySelectorAll('.mdc-tab__ripple, .mdc-ripple-surface, .mdc-fab').forEach (element) ->
    MDCRipple.attachTo element

  # Text field initialization
  document.querySelectorAll('.mdc-text-field').forEach (element) ->
    new MDCTextField(element)

  # Select menu initialization
  # MDC Select uses a hidden input field to store the actual value. For these no 'input' or 'change' are fired, so if JS code needs to subscribe to changes of a select it must be able to find the actual MDCSelect instance and subscribe to its 'change' event.
  window.mdc_selects = []
  document.querySelectorAll('.mdc-select').forEach (element) ->
    window.mdc_selects.push {element: element, mdc_select: new MDCSelect(element)}

  # Snackbar initialization
  document.querySelectorAll('.mdc-snackbar').forEach (element) ->
    snackbar = new MDCSnackbar(element)
    snackbar.open()

  # Banner initialization
  document.querySelectorAll('.mdc-banner').forEach (element) ->
    banner = new MDCBanner(element)
    banner.open()

  # Switch initialization
  document.querySelectorAll('.mdc-switch').forEach (element) ->
    new MDCSwitch(element)

  # Tooltip initialization
  document.querySelectorAll('.mdc-tooltip').forEach (element) ->
    new MDCTooltip(element)

  # Tabbar initialization
  document.querySelectorAll('.mdc-tab-bar').forEach (element) ->
    new MDCTabBar(element)

  ####
  # Pagy auto incremental initialization
  ####
  load_next_page = ->
    # prevent multiple loading
    return if $('#pagy_next_link').data("loading")

    window_bottom  = $(window).scrollTop() + $(window).height()
    pagy_container = $('#pagy_container')
    return if pagy_container.length == 0
    element_bottom = pagy_container.offset().top + $('#pagy_container').height()
    if (window_bottom >= element_bottom)
      pagy_next_link = $('#pagy_next_link')
      # The link will not be rendered when the last page is fetched so the click would raise an exception
      return if pagy_next_link.length == 0

      pagy_next_link[0].click()
      pagy_next_link.data("loading", true)

  window.addEventListener('resize', load_next_page)
  window.addEventListener('scroll', load_next_page)
  window.addEventListener('load',   load_next_page)

  ####
  # File-input fake field initialization
  # HACK: This fakes displaying the file name of an uploaded file in the upload input, since material-components-web does not properly support file input fields.
  ####
  document.querySelectorAll('.hs-file-input').forEach (element) ->
    $('#' + element.dataset.field).bind('change', ->
      element.textContent = document.getElementById(element.dataset.field).files[0].name
    )

  ####
  # Dialog modals initialization
  # NOTE: Dialogs expect a '.mdc-dialog'-element with a set id. The element activating the dialog (e.g. a button) is expected to have the same id suffixed with '_activation'.
  # Ex: dialog modal has class="mdc-dialog" and id="my_cool_dialog". The element that activates the dialog should have id="my_cool_dialog_activation"
  ####
  document.querySelectorAll('.mdc-dialog').forEach (element) ->
    dialog = new MDCDialog(element)
    expected_activation_id_selector = '#' + element.id + '_activation'
    document.querySelector(expected_activation_id_selector).addEventListener('click', (event) ->
      dialog.open())

  ####
  # Datepicker initialization
  ####
  document.querySelectorAll('.datepicker').forEach (element) ->
    picker = new MaterialDateTimePicker().on('submit', (value) ->
      oldValue = element.value
      element.value = value.format("DD.MM.YYYY")
      # HACK to ensure that the label does not overlap with the value when the field was previously empty.
      element.nextSibling.classList.add('mdc-floating-label--float-above')
      element.dispatchEvent(new CustomEvent('datepicker_changed', detail: {oldValue: oldValue})))
    element.addEventListener('focus', ->
      picker.open())

  ####
  # Datetime picker initialization
  ####
  document.querySelectorAll('.datetime-picker').forEach (element) ->
    picker = new MaterialDateTimePicker().on('submit', (value) ->
      oldValue = element.value
      element.value = value.format("DD.MM.YYYY, HH:mm")
      # HACK to ensure that the label does not overlap with the value when the field was previously empty.
      element.nextSibling.classList.add('mdc-floating-label--float-above')
      element.dispatchEvent(new CustomEvent('datetimepicker_changed', detail: {oldValue: oldValue})))
    element.addEventListener('focus', ->
      picker.open())

  ####
  # Event handler updating an appointment suggestions end time field if the start time changes
  # TODO-digibase-home: This should probably not live here due to being too specific for 'shared'
  ####
  $('#appointment_suggestion_start_time_field').on('datetimepicker_changed', (event) ->
    new_start_value = moment(event.target.value, 'DD.MM.YYYY, HH:mm')
    old_start_value = moment(event.detail.oldValue, 'DD.MN.YYYY, HH:mm')
    end_value = moment($('#appointment_suggestion_end_time_field').val(), 'DD.MM.YYYY, HH:mm').add((new_start_value - old_start_value), 'ms')
    document.querySelector('#appointment_suggestion_end_time_field').value = end_value.format('DD.MM.YYYY, HH:mm')
  )
