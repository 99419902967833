const XSMALL_MIN = 0;
const XSMALL_MAX = 599;
const SMALL_MIN = 600;
const SMALL_MAX = 1023;
const MEDIUM_MIN = 1024;
const MEDIUM_MAX = 1439;
const LARGE_MIN = 1440;
const LARGE_MAX = 1919;
const XLARGE_MIN = 1920;
const XLARGE_MAX = Number.POSITIVE_INFINITY;

const sizes = {
  xsmall: {min: XSMALL_MIN, max: XSMALL_MAX},
  small: {min: SMALL_MIN, max: SMALL_MAX},
  medium: {min: MEDIUM_MIN, max: MEDIUM_MAX},
  large: {min: LARGE_MIN, max: LARGE_MAX},
  xlarge: {min: XLARGE_MIN, max: XLARGE_MAX}
};

export function layoutSize() {
  for (const name in sizes) {
    if (sizes.hasOwnProperty(name)) {
      const size = sizes[name];
      if (window.innerWidth >= size.min && window.innerWidth <= size.max) {
        return name;
      }
    }
  }
}

export function isAtLeast(size) {
  return window.innerWidth >= sizes[size].min;
}

export function isAtMost(size) {
  return window.innerWidth <= sizes[size].max;
}

export function isXSmall() {
  return isAtMost('xsmall');
}

export function isSmall() {
  return isAtLeast('small') && isAtMost('small');
}

export function isMedium() {
  return isAtLeast('medium') && isAtMost('medium');
}

export function isLarge() {
  return isAtLeast('large') && isAtMost('large');
}

export function isXLarge() {
  return isAtLeast('xlarge');
}
